import React from 'react';

// import clock
import FlipClockCountdown  from '@leenguyen/react-flip-clock-countdown';

// clock css
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

// import video
import VideoBg from '../src/assets/video.mp4';

// import logo
import logoSvg from '../src/assets/logo_white.png'; 

// import css
import './styles.css';


const App = () => {
  return <section className='page'>
    {/*  overlay */}
    <div className='overlay'></div>
    {/* video */}
    <video src={VideoBg} autoPlay loop muted></video>
    {/* content */}
    <div className='page_content'>
    <img src={logoSvg} alt='VA Capital Group' />
      <h1>Launching Soon</h1>
      <h3>
        Leave your email and we'll let you know once the site goes live.
      </h3>
      {/* clock */}
      <FlipClockCountdown 
        to={new Date('2023-06-30T14:27:32.635Z') + 24 * 3600 * 1000 + 5000}
        className='flip-clock'
        labels={['DAYS','HOURS','MINUTES','SECONDS']}
        duration={0.5}
      />
      {/* button */}
      <button className='btn'>Notify Me</button>
    </div>
  </section>;
};

export default App;
